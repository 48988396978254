.menu-wrapper {
	z-index: 9999999;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	padding-top: 150px;
	padding-left: 120px;
	padding-right: 100px;
	background-color: black;
}

.menu-button {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 100px;
	line-height: 120.5%;
	/* identical to box height, or 157px */

	display: flex;
	align-items: center;
	text-transform: uppercase;
	text-decoration: none;

	color: #fb4a1e;

	margin-right: 20px;
	cursor: pointer;
}

.btm-section {
	width: 80vw;
	margin-left: 120px;
	position: absolute;
	top: 80vh;
	left: 0;
	right: 0;
}

.menu {
	width: 250px;
	height: 80px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px 10px;
	gap: 10px;

	font-family: "Poppins";
	font-style: normal;
	font-weight: 200;
	font-size: 40px;
	line-height: 100%;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	color: #ffffff;
	mix-blend-mode: normal;
	text-decoration: none;

	position: absolute;
	left: 50px;
	top: 30px;

	/* red */

	background: #fb4a1e;
	/* red */

	border: 1px solid #fb4a1e;
	border-radius: 100px;
	cursor: pointer;
}

.main-wrapper {
	position: absolute;
	z-index: 999;
	top: 10px;
	left: 1vw;
}

.close-button {
	position: absolute;
	left: 80vw;
	top: 10vh;
	cursor: pointer;
}

@media (max-width: 800px) {
	.main-wrapper {
		/* position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 3;
		height: 100vh;
		width: 100vw; */
	}
	.menu-wrapper {
		padding-top: 0;
		padding-left: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.menu-button {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 70px;
		line-height: 108.5%;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		text-decoration: none;
		color: #fb4a1e;
	}
	.close-button {
		width: 10px;
		height: 10px;
		position: absolute;
		left: 58vw;
		top: 20px;
		cursor: pointer;
	}
	.btm-section {
		width: 80vw;
		margin-left: 0;
		position: absolute;
		top: 80vh;
		left: 20px;
		right: 0;
	}

	.menu {
		width: 338px;
		height: 109px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 0;
		gap: 0;

		position: fixed;
		top: auto;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);

		background: #fb4a1e;

		border: 1px solid #fb4a1e;
		border-radius: 100px;
		cursor: pointer;

		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 60px;
		line-height: 120.5%;

		display: flex;
		align-items: center;
		text-transform: uppercase;

		/* white */

		color: #ffffff;
	}

	.arrow-img {
		display: none;
	}
}

@media (max-height: 700px) {
	.main-wrapper {
		/* position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 3;
		height: 100vh;
		width: 100vw; */
	}
	.menu-wrapper {
		padding-top: 0;
		padding-left: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.menu-button {
		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 70px;
		line-height: 108.5%;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		text-decoration: none;
		color: #fb4a1e;
	}
	.close-button {
		width: 10px;
		height: 10px;
		position: absolute;
		left: 58vw;
		top: 20px;
		cursor: pointer;
	}
	.btm-section {
		width: 80vw;
		margin-left: 0;
		position: absolute;
		top: 80vh;
		left: 20px;
		right: 0;
	}

	/* .menu {
		width: 338px;
		height: 109px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 0;
		gap: 0;

		position: fixed;
		top: auto;
		left: 50%;
		bottom: 20px;
		transform: translateX(-50%);

		background: #fb4a1e;

		border: 1px solid #fb4a1e;
		border-radius: 100px;
		cursor: pointer;

		font-family: "Poppins";
		font-style: normal;
		font-weight: 600;
		font-size: 60px;
		line-height: 120.5%;

		display: flex;
		align-items: center;
		text-transform: uppercase;


		color: #ffffff;
	} */

	.arrow-img {
		display: none;
	}
}
